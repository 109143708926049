import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import { forEach, random } from 'lodash'
import CountdownTimer, { zeroPad } from 'react-countdown-now';
import ReactPlayer from 'react-player'

import { Layout, Block, Header, MetaTags } from '../components'

import logo from '../assets/images/logo-2020.svg'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { container, padding, bgIcon, bgImage, hoverState } from '../styles/global';
import { grey, bronze, yellow, brown } from '../styles/colors';
import themes, { getThemeData } from '../styles/themes';
import { media } from '../styles/utils';
import { getRandomIndex } from '../utils';


class Index extends React.Component {

	state = {
		activeHoverSlug: true,
		hoverImages: []
	}

	componentWillMount = () => {
		this.setState({
            data: JSON.parse(this.props.data.wordpressHome.acf_json),
            globalData: JSON.parse(this.props.data.wordpressGlobal.acf_json),
			categories: this.props.data.allWordpressCategories.edges,
		})
	}

	componentDidMount() {
		this.setState({
			hoverImages: this.randomiseImages()
		})
	}

	randomiseImages = () => {
		const { categories } = this.state;

		let images = [];

		categories.map((edge, i) => {
			const cat = edge.node;
			const catFields = JSON.parse(edge.node.acf_json);

			let previousAlignment = getRandomIndex(['left', 'right']);
			const verticalSpacing = 20;
	
			catFields.images.map((image, i) => {
				previousAlignment = previousAlignment == 'left' ? 'right' : 'left';

				image.align = previousAlignment == 'left' ? 'left' : 'right',
				image.transformX = `${random(0, 10)}%`,
				image.transformY = `${random(0, 10)}%`,
				image.slug = cat.slug;
				image.top = `${i == 0 ? verticalSpacing : (i + 1) * verticalSpacing }%`

				images.push(image);
			})
		})

		return images
	}

	renderHoverImages = () => {
		const { activeHoverSlug, hoverImages } = this.state;

		return hoverImages.map((image, i) => {
			return (
				<Image
					active={activeHoverSlug == image.slug}
					src={image.image.sizes && image.image.sizes.medium2}
					style={{
						top: image.top,
						left: image.align == 'left' && image.transformX,
						right: image.align == 'right' && image.transformX,
					}}
					key={i}
				/>		
			)
		})
	}

	renderHeroCategories = () => {
		const { categories } = this.state;

        console.log('categories',categories);

		return categories.map((edge, i) => {
			const cat = edge.node;
			const catFields = JSON.parse(edge.node.acf_json);

			if (catFields) {
				return (
					<Category
						key={i}
						theme={{
							text: catFields.text_colour,
							background: catFields.background_colour
						}}
						layout={catFields.layout}
						onClick={() => navigate(`/category/${cat.slug}`)}
						onMouseEnter={() => this.setState({activeHoverSlug: cat.slug})}
						onMouseLeave={() => this.setState({activeHoverSlug: null})}
					>
						{cat.title}
					</Category>
				)
			}
		})
	}

	renderCountdownTimer = (props) => {
		const { days, hours, minutes, seconds, completed } = props

		const timeSegments = [
			{
				label: 'days',
				value: days
			},
			{
				label: 'hours',
				value: hours
			},
			{
				label: 'minutes',
				value: minutes
			},
			{
				label: 'seconds',
				value: seconds
			},
		]

		const segments = timeSegments.map((item, i) => {
			return (
				<Segment 
					key={i}
					hasSemicolon={i !== (timeSegments.length - 1)}
				>
					<Heading>
						{zeroPad(item.value, 2)}
						{i !== (timeSegments.length - 1) && ':'}
					</Heading>
					<Subheading>{item.label}</Subheading>
				</Segment>
			)
		})

		return (
			<Timer>
				{segments}
			</Timer>
		)		
	}

	renderCountdown = () => {
		const { data } = this.state;
		if (!data.header_countdown_activate && !data.header_live_stream_activate) return;

		return (
			<Countdown>
				<Container>
					<HeaderWrapper>
						<Header
							theme={{
								colour: yellow,
								background: 'transparent'
							}}
						/>
					</HeaderWrapper>

					<Logo
						countdownActive={true}
					/>
			
					{!data.header_live_stream_activate && (
						<CountdownTimer
							date={(data.header_countdown_date_unix * 1000) - (39600000)}
							intervalDelay={0}
							precision={3}
							renderer={this.renderCountdownTimer}
							autoStart
						/>
					)}

					{data.header_live_stream_activate && (
						<LiveStream>
							<StreamChat>
                                <iframe src="https://app.sli.do/event/dphiobkh" frameBorder="0"></iframe>
							</StreamChat>
							<StreamVideo>
                                <iframe src="https://vimeo.com/event/1370619/embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
							</StreamVideo>
						</LiveStream>

					)}
				
					{!data.header_live_stream_activate && data.header_register_text && (
						<Text
							dangerouslySetInnerHTML={{__html: data.header_register_text}}
						/>
					)}
				</Container>

				{data.header_video && (
					<Video
						url={data.header_video}
						playing
						loop
						muted
						width={'100%'}
						height={'100%'}
					/>
				)}
			</Countdown>
		)
	}

	renderHero = () => {
		const { data } = this.state;

		return (
			<Hero
				countdownActive={data.header_countdown_activate}
			>
				<Container>
					{!data.header_countdown_activate && (
						<>
							<HeaderWrapper>
								<Header
									theme={{
										colour: yellow,
										background: 'transparent'
									}}
								/>
							</HeaderWrapper>

							<Logo/>
						</>
					)}

					{this.renderHeroCategories()}
					{/* {this.renderHoverImages()} */}

					<Enter
                        to={'/apply'}
                    >
						Enter here
					</Enter>
				</Container>
			</Hero>
		)
	}

	renderBlocks = () => {
        const { blocks } = this.state.data;

		return blocks && blocks.map((block, i) => {
			return (
				<Block
					id={'about'}
					layout={block.acf_fc_layout}
					key={i}
					{...block}
				/>
			)
		})
	}

	renderStickyHeader = () => {
		return (
			<StickyHeader>
				<Header
					showNav={true}
					showSubmit={true}
					sticky={true}
					theme={{
						colour: brown,
						background: grey
					}}
				/>
			</StickyHeader>
		)
	}

	renderPostStreamVideo = () => {
		const { data } = this.state;
		if (!data.post_event_video_display) return;

		const resolveURL = (iframe) => {
			if (iframe) {
				return iframe?.match(/src="(.+?)"/)?.[1]
			}
		}

		return (
			<PostEventVideo>
				<Container>
					<VideoEmbed
						dangerouslySetInnerHTML={{__html: data.post_event_video}}
					/>
				</Container>
			</PostEventVideo>
		)
	}

	render() {       
		const { data, globalData } = this.state;

        let meta_description = data.meta_description;
        if(!meta_description) meta_description = globalData.meta_description_default;

		return (
			<Layout
				// Replace with term slug
				theme={'residential-architecture'}
				footerImage={data.footer_bg_image && data.footer_bg_image.sizes.xlarge}
			>
                <MetaTags
                    title={'Home'}
                    description={meta_description}
                />
				<Wrapper>
					{this.renderCountdown()}
					{this.renderHero()} 
					{this.renderPostStreamVideo()}
					{this.renderStickyHeader()}
					{this.renderBlocks()}
				</Wrapper>
			</Layout>
		)
	}
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Container = styled.div`
	${container};
	${padding};
	flex-direction: column;
	width: 100%;
`

const Video = styled(ReactPlayer)`
    width: 100% !important;
`

const VideoEmbed = styled.div``


// Live Stream

const StreamChat = styled.div``
const StreamVideo = styled.div``

const LiveStream = styled.div`
	display: flex;
	width: 100%;	
	position: relative;
	z-index: 2;
	margin-bottom: 70px;

	${StreamChat} {
		position: relative;

		iframe {
			width: 100%;
			height: 100%;
			min-width: 340px;
		}
	}
	
	${StreamVideo} {
		width: 100%;

		iframe {
			padding-bottom: 56.25%;	
			width: 100%;
			height: 100%;
		}
	}
	
	${media.tablet`
		display: flex;
		flex-direction: column;
		margin-bottom: 0;

		${StreamVideo} {
			order: 1;

			iframe {
				padding-bottom: 0;	
				width: 100%;
				height: 52vw;
			}
		}

		${StreamChat} {
			order: 2;
			height: 520px;
			margin-top: -4px;
		
			iframe {
				min-width: 100%;
			}
		}
	`}
`

// Countdown

const Countdown = styled.div`
	background-color: ${bronze};
	/* background-image: url(${require('../assets/images/home-bg.jpg')}); */
	${bgImage}
	padding: 180px 0 40px;
	width: 100%;
	max-width: 100vw;
	overflow: hidden;
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	video {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 1;
		object-fit: cover;
	}

	${Container} {
		align-items: center;

		${Text} {
			font-family: 'Playfair Italic';
			font-size: 26px;
			line-height: 30px;
			letter-spacing: 0.005em;
			max-width: 285px;
			text-align: center;
			position: relative;
			z-index: 2;
			
			&, p, a {
				color: ${yellow};
			}

			a { 
				position: relative;

				&::after {
					content: '';
					position: absolute;
					bottom: 4px;
					left: 0;
					right: 0;
					border-bottom: 1px solid ${yellow};
				}
			}
		}
	}

	${media.phone`
		padding: 150px 0 60px;
	`}
`

const Logo = styled.div`
	background-image: url(${logo});
	max-width: 450px;
	width: 100%;
	height: 280px;
	${bgIcon}
	margin-bottom: 120px;
	z-index: 3;

	${media.phone`
		max-width: 308px;
		height: 195px;
		margin-bottom: 68px;
	`}

	/* Countdown Active */

	${props => {
		if (props.countdownActive) return css`
			margin-bottom: 70px;
		`
	}}
`

// Timer

const Segment = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 75px;
	
	${Heading},
	${Subheading} {
		color: ${yellow};
		font-family: 'Helvetica';
	}

	${Heading} {
		font-size: 86px;
		line-height: 1em;
		font-weight: 600;
		margin: 0;

		${media.phone`
			font-size: 50px;
		`}
	}

	${Subheading} {
		margin: 0;
		text-transform: uppercase;
		font-size: 19px;

		${media.phone`
			font-size: 12px;
		`}
	}

	${props => {
		if (props.hasSemicolon) return css`
			${Subheading} {
				transform: translateX(-13px);

				${media.phone`
					transform: translateX(-8px);
				`}
			}
		`
	}}
`

const Timer = styled.div`
	display: flex;
	position: relative;
	z-index: 2;
`

// Hero

const Hero = styled.div`
	background-color: ${bronze};
	background-image: url(${require('../assets/images/Background-TDF-Awards.jpg')});
	${bgImage}
	padding: 270px 0 170px;
	width: 100%;
	max-width: 100vw;
	overflow: hidden;
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;

	${Container} {
		align-items: center;
	}

	${media.phone`
		padding: 150px 0 60px;
	`}

	/* Countdown Active */

	${props => {
		if (props.countdownActive) return css`
			padding: 230px 0 170px;	
		`
	}}
`

const HeaderWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
`

// Hero Cateogories

const Image = styled.img`
	width: auto;
	height: 315px;
	position: absolute;
	z-index: 1;
	padding: relative;
	opacity: ${props => props.active ? 1 : 0};
	transition: opacity 0.6s ease;

	${media.phone`
		display: none;
	`}
`

const Category = styled.div`
	font-size: 30px;
	letter-spacing: 0.027em;
	color: ${yellow};
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
	transition: color 0.2s ease;
	z-index: 2;
	padding: relative;
	text-align: center;

	&:not(:last-of-type) {
		margin-bottom: 30px;

		${media.phone`
			margin-bottom: 25px;
		`}
	}

	/* Hover Theme */

	${props => {
		if (props.theme) return css`
			&:hover {
				color: ${props.theme.text};

				${Image} {
					opacity: 1;
				}
			}
		`
	}}

	/* Hover Images */

	${props => {
		if (props.layout) return css`
			${Image} {

				&[data-index="0"] {
					top: 233px;
					right: 15px;
				}

				&[data-index="1"] {
					top: 20%;
					left: 167px;
				}

				&[data-index="2"] {
					bottom: 130px;
					left: 30px;
				}
			}
		`
	}}

	${media.phone`
		font-size: 20px;
	`}
`

const Enter = styled(Link)`
	font-size: 30px;
	letter-spacing: 0.027em;
	color: ${yellow};
	margin-bottom: 30px;
	font-weight: 300;
	text-transform: uppercase;
	position: relative;
	margin-top: 90px;
	${hoverState}

	&::after {
		content: '';
		position: absolute;
		bottom: 4px;
		left: 0;
		right: 0;
		border-bottom: 1px solid ${yellow};
	}

	${media.phone`
		margin-top: 60px;
		font-size: 20px;

		&::after {
			bottom: 2px;
		}
	`}
`

// Sticky Header

const StickyHeader = styled.div`
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 10;
	display: flex;
	justify-content: center;

	${media.phone`
		top: 0px;
	`}
`

// Post Event Video

const PostEventVideo = styled.div`
	background-color: #061C30;
	${bgImage}
	padding: 90px 0;
	width: 100%;
	max-width: 100vw;
	overflow: hidden;
	position: relative;
	margin-top: -1px;

	display: flex;
	flex-direction: column;
	align-items: center;

	${Container} {
		align-items: center;
		width: 100%;
	}


	${VideoEmbed} {
		width: 100%;
		height: 100%;
	}

	iframe {
		/* padding-bottom: 56.25%;	 */
		width: 100%;
		height: 56vw;
		max-height: 720px;
	}

	${media.phone`
		padding: 32px 0;
	`}
`


export const query = graphql`
	query {
		wordpressHome {
			acf_json	
		}	

        wordpressGlobal {
			acf_json	
		}	

		allWordpressCategories {
			edges {
				node {
					title
					slug
					acf_json
				}
			}
		}
	}
`

export default Index

